"use client"

import { useEffect } from "react";

export default function AutoLogin({ login, provider }: { login: (provider: string) => Promise<void>, provider: string }) {

    useEffect(() => {
        // const interval = setInterval(() => {
        //     login(provider)
        //     clearInterval(interval);
        // }, 100);
        login(provider)
    });

    return <></>
}